import React, { useContext , useState, useEffect} from 'react'
import userClient from 'clients/user-client'
import { useRouter } from 'next/router'
import moment from 'moment'
import cookies from 'next-cookies'
import jwtDecode from 'jwt-decode'
import { useAlert } from 'react-alert'

import Markdown from 'components/markdown'

import { isServerSide, isObject, isEmpty, isNull, isArray, isFunction, parseError, responseCallback } from 'libs/utils'

const UserContext = React.createContext()

export const ProvideUser = ({ children, data }) => {
	const dataWrapper = useProvideUser( data.token, data.user )

	return (
		<UserContext.Provider value={dataWrapper}>
			{children}
		</UserContext.Provider>
	)
}

// Hook for child components to get the User object ...
// ... and re-render when it changes.
export const useUser = () => {
	return useContext(UserContext)
}

// Provider hook that creates Data object and handles state
const useProvideUser = (t, u) => {

	const alert = useAlert()
	const router = useRouter()
	const [token, setToken] = useState(t)
	const [me, setMe] = useState(u)
	const [testimonials, setTestimonials] = useState([])
	const [orders, setOrders] = useState( null )
	const [addresses, setAddresses] = useState( null )
	const [callback, setCallback] = useState( () => null )


	// User Register
	const register = async (values) =>{
		return await userClient.create(values)
	}

	// Handle user registration
	const submitRegister = async (values, { setSubmitting }) => {
		const response = await register( values )

		responseCallback(
			response,
			response => {
				setSubmitting( false )
				alert.success(
					'Votre compte à bien été créé. Vérifiez votre boite mail pour valider votre compte.',
					{
						onClose: () => {
							router.push('/compte')
						}
					}
				)
			},
			error => {
				setSubmitting( false )
				let errorsMessage = `Une erreur est survenue lors de la création du compte.\n\n- ${parseError( error, '\n- ' )}`
				alert.error( <Markdown>{ errorsMessage }</Markdown> )
			}
		)

		return response
	}

	// User Login
	const signIn = async values => {
		if( isNull( token ) ) {
			return await getToken( values )
		}

		return token
	}

	// Handle user login
	const submitLogin = async values => {
		const response = await signIn( values )

		return responseCallback(
			response,
			response => response,
			error => {
				alert.error( 'Identifiant ou mot de passe incorrect.' )
				return error
			}
		)
	}

	// Sign user out
	const signOut = () => {
		clearUserContext()
		document.cookie = `userToken =; expires=Thu, 01 Jan 1970 00:00:00 GMT; path =/`;

		return null
	}

	// Get user auth token
	const getToken = async values => {
		const response = await userClient.login(values)

		responseCallback(
			response,
			response => {
				setToken( response.token )
				storeToken( response.token, values.remember_me )
			}
		)

		return isObject( response ) && ('token' in response) ? response.token : response
	}

	// Store user auth token in cookies
	const storeToken = (token, remember_me) => {

		if( remember_me ) {
			const expirationDate = moment().add( 90, 'days' );
			document.cookie = `userToken =${token}; expires=${expirationDate._d}; path =/`
		} else {
			document.cookie = `userToken =${token}; path =/`
		}
	}

	// Use auth token to get user info
	const checkUser = async () => {

		if( ! token ) {
			clearUserContext()
		}

		if( token ) {
			const response = await userClient.getUser()

			return responseCallback(
				response,
				response => {
					setMe( response )
					return response
				},
				signOut
			)
		}
	}

	// User profile change
	const updateUser = async (values) => {

		const response = await userClient.updateUser( values )

		return responseCallback(
			response,
			response => checkUser(),
			error => {
				alert.error("Modification impossible.\nMerci de vérifier les informations fournies.")
				return error
			}
		)
	}

	// User password change
	const updatePassword = async (values) => {

		const response = await userClient.passwordReset(token, values.password)

		return responseCallback(
			response,
			signOut,
			error => {
				alert.error("Ajout impossible.\nMerci de vérifier les informations fournies.")
				return error
			}
		)
	}

	const requestPasswordChange = async values => {

		if( ! isObject( values ) || ! ( 'email' in values ) )
			return false

		const response = await userClient.requestPasswordChange( values.email )

		return responseCallback(
			response,
			response => response,
			error => {
				alert.error( <Markdown>{ parseError( error, '\n\n' ) }</Markdown> )
				return error
			}
		)
	}

	const passwordReset = async values => {

		if( ! isObject( values ) || ! ( 'token' in values ) || ! ( 'password' in values ) )
			return false

		const response = await userClient.passwordReset( values.token, values.password )

		return responseCallback(
			response,
			response => response,
			error => {
				alert.error( <Markdown>{ parseError( error, '\n\n' ) }</Markdown> )
				return error
			}
		)
	}

	// Get user address book
	const checkAddresses = async forced => {

		if( ( forced || isNull( addresses ) ) && ! isNull( token ) ) {
			const response = await userClient.getAddresses()

			return responseCallback(
				response,
				response => {
					setAddresses( isArray( response ) ? response : [] )
					return response
				}
			)
		}

		return addresses
	}

	// Add to user address book
	const addAddress = async values => {
		const response = await userClient.addAddress(values)

		return responseCallback(
			response,
			async () => await checkAddresses( true ),
			error => {
				alert.error("Ajout impossible.\nMerci de vérifier les informations fournies.")
				return error
			}
		)
	}

	// update one address from address book
	const updateAddress = async values => {
		const response = await userClient.updateAddress(values)

		console.log(response);

		return responseCallback(
			response,
			async () => await checkAddresses( true ),
			error => {
				alert.error("Modification impossible.\nMerci de vérifier les informations fournies.")
				return error
			}
		)
	}

	// Delete address from address book
	const removeAddress = async id => {
		const response = await userClient.removeAddress( id )

		return responseCallback(
			response,
			async () => await checkAddresses( true ),
			error => {
				alert.error( "Un problème est survenu lors de la suppression de l'adresse." )
				return error
			}
		)
	}

	// Check user orders
	const checkOrders = async forced => {
		if( forced || isNull( orders ) && token !== null ) {
			const response = await userClient.getOrders()

			return responseCallback(
				response,
				response => setOrders( isArray( response ) ? response : [] )
			)
		}
	}

	// Check user Testimonials
	const checkTestimonials = async forced => {
		if( forced || isEmpty( testimonials ) && ! isNull( token ) ) {
			const response = await userClient.getTestimonials()

			return responseCallback(
				response,
				response => setTestimonials( response )
			)
		}
	}

	// Clear the context after disconnect or sessions is off
	const clearUserContext = () => {
		setToken(null)
		setMe(null)
		setAddresses(null)
		setOrders(null)
		setTestimonials(null)
	}

	// Listen to token changes to populate the context with get data
	useEffect(() => {
		checkUser()
		checkAddresses()
		checkOrders()
		checkTestimonials()
	}, [token])

	// Listen to 'me' and trigger callback then, used to reload page after user is setted
	useEffect(() => {
		if ( isFunction( callback ) ) {
			callback()
		}
	}, [me])

	return {
		isUserLoggedIn: ! isNull( me ),
		isRetailer: ! isNull( me ) && me.group === 'retailer',
		me,
		testimonials,
		setTestimonials,
		orders,
		checkOrders,
		addresses,
		setAddresses,
		signIn,
		signOut,
		updateUser,
		addAddress,
		updateAddress,
		removeAddress,
		submitLogin,
		register,
		submitRegister,
		requestPasswordChange,
		passwordReset
	}
}

const checkTokenValidity = decoded => {

	const now = Date.now().valueOf() / 1000

	if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
	  throw new Error(`token expired: ${JSON.stringify(decoded)}`)
	}

	if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
	  throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
	}
}

export async function getUserData( appContext ) {
	let t
	let u = null

	if( isServerSide() ){
		t = cookies( appContext.ctx ).userToken || null
		userClient.setCtx( appContext.ctx )
	} else {
		t = cookies( appContext ).userToken || null
		userClient.setCtx( appContext )
	}

	if(t) {
		try {
			checkTokenValidity(jwtDecode(t))
			u = await userClient.getUser()
		} catch (error) {
			t = null
		}
	}

	return {
		token : t,
		user : u
	}
}
