import createClients from "./create-clients";

const config = {
	middlewares: false,
	fake_api: false,
	ctx: false,
};

const params = {};

let ApiClient;

const getApiClient = () => {
	if (!ApiClient) ApiClient = createClients(config).ApiClient;

	return ApiClient;
};

export default {
	setCtx: (ctx) => (config.ctx = ctx),

	create: (values) => {
		params.firstName = values.firstName;
		params.lastName = values.lastName;
		params.gender = values.gender;
		params.newsletters = values.newsletters;
		params.email = values.email;
		params.plainPassword = values.plainPassword.first;
		return getApiClient().post("/register", { params });
	},

	login: (values) => {
		params.email = values.username;
		params.password = values.password;
		return getApiClient().post("/login", { params });
	},

	getUser: () => getApiClient().get("/me", { params }),

	updatePassword: (currentPassword, newPassword) => {
		params.currentPassword = currentPassword;
		params.newPassword = newPassword;
		return getApiClient().put("/me/change-password", null, { params });
	},

	requestPasswordChange: (email) =>
		getApiClient().put("/request-password-reset", null, { params: { email } }),

	passwordReset: (token, password) =>
		getApiClient().put(`/password-reset/${token}`, null, {
			params: { password },
		}),

	updateUser: (values) => {
		params.firstName = values.firstName;
		params.lastName = values.lastName;
		params.gender = values.gender;
		params.newsletters = values.newsletters;
		return getApiClient().put("/me", null, { params });
	},

	getAddresses: () => {
		return getApiClient().get("/address-book", { params });
	},

	updateAddress: (values) => {
		params.firstName = values.firstName;
		params.lastName = values.lastName;
		params.street = values.street;
		params.city = values.city;
		params.postcode = values.postcode;
		params.company = values.company;
		params.phoneNumber = values.phoneNumber;
		params.countryCode = "FR";
		params.provinceName = "";
		params.provinceCode = "";
		return getApiClient().put("/address-book", values.id, { params });
	},

	addAddress: (values) => {
		params.firstName = values.firstName;
		params.lastName = values.lastName;
		params.phoneNumber = values.phoneNumber;
		params.company = values.company;
		params.countryCode = values.countryCode;
		params.street = values.street;
		params.postcode = values.postcode;
		params.provinceName = "";
		params.city = values.city;
		params.phoneNumber = values.phoneNumber;
		return getApiClient().post("/address-book/", { params });
	},

	removeAddress: (id) =>
		getApiClient().destroy("/address-book", id, { params }),

	getOrders: () => getApiClient().get("/orders", { params }),

	getTestimonials: () => getApiClient().get("/testimonials/me", { params }),

	subscribeNewsletter: (email) => getApiClient().post("/newsletter", { params: { email } }),

	shippableNumbers: () => getApiClient().get( '/shippable-numbers', { params } )
};
